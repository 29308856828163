<template>
  <v-main>
    <Loader :visible="isLoading" />
    <v-container fluid style="max-width: 600px">
      <div class="mb-5">
        <v-img :src="require('@/assets/img/Tway-logotipo-violet.png')" max-height="50" contain />
      </div>

      <v-divider />

      <div class="mt-5 text-center">
        <v-icon x-large :color="success ? `tway-violet` : `black`">
          {{ success ? `mdi-credit-card-plus-outline` : `mdi-credit-card-remove-outline` }}
        </v-icon>
      </div>

      <div
        class="tway-title font-weight-bold text-center pa-5 title"
        :class="success ? `text--tway-violet` : `text--black`"
      >
        {{ message || (success ? successDefaultMessage : errorDefaultMessage) }}
      </div>

      <v-divider />

      <div class="mt-5 text-center caption">Esta ventana se cerrará en {{ closeTime / 1000 }} segundos</div>
    </v-container>
  </v-main>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
  },

  data() {
    return {
      isLoading: false,
      closeTime: 10000,
      success: false,
      message: "",
      successDefaultMessage: "Inscripción exitosa, ya puedes realizar cargos a esta tarjeta.",
      errorDefaultMessage: "No se ha logrado completar la inscripción de la tarjeta.",
    };
  },

  created() {
    this.success = this.$route.params.success === "success";
    this.message = this.$route.query.message;
  },

  mounted() {
    let intervalId = setInterval(() => {
      this.closeTime -= 1000;
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      window.close();
    }, this.closeTime);
  },
};
</script>
